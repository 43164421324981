import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyName: 'GET_MARKETER_AGENCY',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      isMarketerSite: 'GET_IS_MARKETER_SITE',
    }),
    isAgencySite() {
      return this.marketerId !== '' && this.marketerId !== null;
    },
    hostData() {
      let currHostData = null;
      if (this.isAgencySite) {
        currHostData = this.marketerAgencyContent;
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        currHostData = this.whiteLabel;
      }
      return currHostData;
    },
  },
};
